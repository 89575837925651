import { ReactElement, useEffect, useState } from 'react';
import { message } from 'antd';
import { format, parseISO } from 'date-fns';
import { useSetAtom } from 'jotai';
import { Trans, useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';
import Button from 'common/components/button/button.component.tsx';
import { EButtonStyleType } from 'common/components/button/button.conmponent.enums.ts';
import { Loader } from 'common/components/loader/loader.component.tsx';
import PoweredBy from 'common/components/powered-by/powered-by.component.tsx';
import { ESupportLinkType } from 'common/components/support-link/enums/support-link.component.enum.ts';
import SupportLink from 'common/components/support-link/support-link.component.tsx';
import Tooltip from 'common/components/tooltip/tooltip.component.tsx';
import TransactionStatusBadge from 'common/components/transaction-status-badge/transaction-status-badge.component.tsx';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { headerConfigAtom } from 'common/stores/header.store.ts';
import { amountDigitSeparator } from 'common/utils/digit-separator.utils.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { textTrimmingUtil } from 'common/utils/text.utils.ts';
import { setHttps } from 'common/utils/url.utils.ts';
import { BLOCKCHAIN_NETWORK_MAPPING } from 'domains/transaction/constants/transaction.constants.tsx';
import {
  ETransactionKind,
  ETransactionState,
  ETransactionStatus,
} from 'domains/transaction/enums/transaction.enums.ts';
import {
  useGetTransactionDataQuery,
  usePostTransactionRefundMutation,
} from 'domains/transaction/queries/transaction.query.ts';
import { WALLETS_MAPPING } from 'domains/wallet/constants/wallet.constants.tsx';
import { ITransactionPageLoaderData } from 'pages/transaction/interfaces/transaction.interfaces.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import CopyIcon from 'assets/copy.icon.svg?react';
import ErrorTriangleIcon from 'assets/error-triangle.icon.svg?react';
import InfoIcon from 'assets/info.icon.svg?react';
import SuccessCircleIcon from 'assets/success-circle.icon.svg?react';
import DefaultMerchantIcon from 'assets/wallet-logo-icons/default.icon.svg?react';
import {
  SAmount,
  SButtonContainer,
  SCard,
  SCardLine,
  SCardLineLabel,
  SGetAmount,
  SNoData,
  SInfoIconContainer,
  SSupportLinContainer,
  STopContainer,
  STopKind,
  STransactionIdValue,
  STransactionBlockchainIconWrapper,
  STransactionLogoContainer,
  STransactionLogo,
  SWarningLabel,
  SInfiniteScrollLoader,
} from './transaction.page.styles.ts';

const TransactionPage = (): ReactElement => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const setHeaderConfig = useSetAtom(headerConfigAtom);
  const { transactionData: transactionDataFromLoader } = useLoaderData() as ITransactionPageLoaderData;
  const [isRefundBtnLoading, setIsRefundBtnLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idQueryParamValue = searchParams.get(EUrlQueryParams.Id) ?? '';
  const { mutateAsync: mutateAsyncPostTransactionRefundMutation } = usePostTransactionRefundMutation();

  const {
    data: transactionData,
    refetch: refetchTransactionData,
    isError: isTransactionDataError,
    error: transactionDataError,
  } = useGetTransactionDataQuery({
    sep24GUID: idQueryParamValue,
    queryOptions: {
      ...(transactionDataFromLoader ? { initialData: transactionDataFromLoader } : {}),
      refetchOnMount: false,
    }
  });

  useEffect(() => {
    if (isTransactionDataError) {
      captureError(transactionDataError as IError);
    }
  }, [isTransactionDataError, transactionDataError]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (transactionData) {
      setHeaderConfig({
        title: format(parseISO(transactionData.created_at), "MMM d · h:mm a"),
      });
    }
  }, [setHeaderConfig, transactionData]);

  const onClickKycButtonHandler = (): void => {
    navigate(`${ROUTES_MAPPING.PROTECTED.KYC}?${EUrlQueryParams.Id}=${idQueryParamValue}`);
  };

  const walletAddress = transactionData?.kind === ETransactionKind.Deposit
    ? transactionData.details.to
    : transactionData?.details.from;

  const isRefund = (transactionData?.state &&
    [ETransactionState.H2hRefunded, ETransactionState.FpfRefunded, ETransactionState.FpfCreatedForRefund].includes(
      transactionData.state,
    ))
    ?? (
      transactionData?.status === ETransactionStatus.PendingUser
      && transactionData.state === ETransactionState.FpfExpired
    );

  const onRefundBtnHandler = async (): Promise<void> => {
    try {
      setIsRefundBtnLoading(true);

      const { action } = await mutateAsyncPostTransactionRefundMutation({ id: transactionData?.id ?? 'undefined' });

      if (action) {
        window.location.replace(setHttps(action));
      } else {
        // eslint-disable-next-line no-void
        void messageApi.open({
          type: 'success',
          content: t('transaction.alert.refund.statusPending'),
          icon: <SuccessCircleIcon />,
          duration: 2,
          style: {
            marginTop: 'calc(100vh - 60%)',
          },
        });
        await refetchTransactionData();
      }

      setIsRefundBtnLoading(false);
    } catch (error) {
      setIsRefundBtnLoading(false);
      captureError(error as IError);

      await messageApi.open({
        content: t('transaction.alert.refund.error'),
        icon: <span><ErrorTriangleIcon /></span>,
        duration: 2,
        style: {
          marginTop: 'calc(100vh - 60%)',
        },
      });
    }
  };

  const onCloseHandler = (): void => {
    navigate(ROUTES_MAPPING.PROTECTED.TRANSACTIONS);
  };

  const amountIn = transactionData?.details.amount_in ?? '0';
  const amountOut = transactionData?.details.amount_out ?? '0';

  const amountInAsset = transactionData?.details.amount_in_asset
    ? transactionData.details.amount_in_asset.split(':')[1]
    : '';

  const amountOutAsset = transactionData?.details.amount_out_asset
    ? transactionData.details.amount_out_asset.split(':')[1]
    : '';

  const networkFeeAmount = (transactionData?.details.fee_details.breakdown ?? [])
    .filter((item) => item.name === 'network_fee')[0]?.amount;
  const networkFeeAsset = transactionData?.details.fee_details.asset.split(':')[1];

  const processingFeeAmount = (transactionData?.details.fee_details.breakdown ?? [])
    .filter((item) => item.name === 'processing')[0]?.amount;
  const processingFeeAsset = transactionData?.details.fee_details.asset.split(':')[1];

  const isNoData = amountIn === '0' && amountOut === '0';
  const displayedAmountInAsset = amountInAsset || amountOutAsset;
  const displayedAmountOutAsset = amountOutAsset || amountInAsset;

  const transactionDetailsRefreshHandler = async (): Promise<void> => {
    try {
      await refetchTransactionData();
    } catch (error) {
      captureError(error as IError);
    }
  };

  return (
    <PullToRefresh
      onRefresh={transactionDetailsRefreshHandler}
      refreshingContent={
        <SInfiniteScrollLoader>
          <Loader />
        </SInfiniteScrollLoader>
      }
      pullingContent={<> </>}
    >
      <>
        <STopContainer>
          <STransactionLogoContainer>
            <STransactionLogo>
              {(transactionData?.wallet.key && (transactionData.wallet.key in WALLETS_MAPPING)) ? (
                WALLETS_MAPPING[transactionData.wallet.key].icon
              ) : (
                <DefaultMerchantIcon />
              )}
            </STransactionLogo>
            {(transactionData?.blockchain && (transactionData.blockchain in BLOCKCHAIN_NETWORK_MAPPING)) && (
              <STransactionBlockchainIconWrapper>
                {BLOCKCHAIN_NETWORK_MAPPING[transactionData.blockchain].icon}
              </STransactionBlockchainIconWrapper>
            )}
          </STransactionLogoContainer>
          {transactionData?.kind && (
            <STopKind>{transactionData.kind}</STopKind>
          )}
          <SAmount>
            {isNoData ? (
              <SNoData>{t('transaction.info.noData')}</SNoData>
            ) : (
              `${amountDigitSeparator(amountIn)} ${displayedAmountInAsset}`
            )}
          </SAmount>
          <SGetAmount>
            {isNoData
              ? t('transaction.info.description')
              : `${amountDigitSeparator(amountOut)} ${displayedAmountOutAsset}`
            }
          </SGetAmount>
          {transactionData && (
            <TransactionStatusBadge transactionData={transactionData} />
          )}
          {(
            transactionData?.wallet.name
            && transactionData.status === ETransactionStatus.PendingUserTransferStart
            && transactionData.kind !== ETransactionKind.Deposit
          ) && (
            <SWarningLabel>
              <Trans
                i18nKey="transaction.info.warning.finalizeTransaction"
                values={{ wallet: `${transactionData.wallet.name} Wallet App` }}
                components={{ bold: <strong /> }}
              />
            </SWarningLabel>
          )}
        </STopContainer>
        <SCard>
          {transactionData?.details.amount_in && (
            <SCardLine>
              <SCardLineLabel>{t('transaction.info.youPay')}</SCardLineLabel>
              <div>
                {amountDigitSeparator(transactionData.details.amount_in)} {amountInAsset}
              </div>
            </SCardLine>
          )}

          {transactionData?.details.amount_out && (
            <SCardLine>
              <SCardLineLabel>{t('transaction.info.youGet')}</SCardLineLabel>
              <div>
                {amountDigitSeparator(transactionData.details.amount_out)} {amountOutAsset}
              </div>
            </SCardLine>
          )}

          {networkFeeAmount && (
            <SCardLine>
              <SCardLineLabel>
                {t('transaction.info.networkFee')}
                <Tooltip
                  title={t('transaction.info.networkFee.info')}
                >
                  <div id="transaction-details-ntw-fee-info">
                    <InfoIcon />
                  </div>
                </Tooltip>
              </SCardLineLabel>
              <div>{amountDigitSeparator(networkFeeAmount)} {networkFeeAsset}</div>
            </SCardLine>
          )}
          <SCardLine>
            <SCardLineLabel>
              {t('transaction.info.processingFee')}
              <Tooltip placement="topLeft" title={t('transaction.info.processingFe.ingo')}>
                <SInfoIconContainer id="transaction-details-proc-fee-info">
                  <InfoIcon />
                </SInfoIconContainer>
              </Tooltip>
            </SCardLineLabel>
            <div>
              {amountDigitSeparator(processingFeeAmount)} {processingFeeAsset}
            </div>
          </SCardLine>
          <hr />
          {transactionData?.kind && (
            <SCardLine>
              <SCardLineLabel>{t('transaction.info.type')}</SCardLineLabel>
              <div style={{ textTransform: 'capitalize' }}>{transactionData.kind}</div>
            </SCardLine>
          )}
          {transactionData?.created_at && (
            <SCardLine>
              <SCardLineLabel>{t('transaction.info.time')}</SCardLineLabel>
              <div>{format(parseISO(transactionData.created_at), "MMM d, yyyy 'at' h.mm aaaa")}
              </div>
            </SCardLine>
          )}
          {transactionData?.wallet.name && (
            <SCardLine>
              <SCardLineLabel>{t('transaction.info.wallet')}</SCardLineLabel>
              <div>{transactionData.wallet.name}</div>
            </SCardLine>
          )}
          {transactionData?.blockchain && (
            <SCardLine>
              <SCardLineLabel>{t('transaction.info.blockchain')}</SCardLineLabel>
              <div style={{ textTransform: 'capitalize' }}>{transactionData.blockchain}</div>
            </SCardLine>
          )}
        </SCard>

        <SCard>
          {transactionData?.id && (
            <SCardLine>
              <SCardLineLabel>{t('transaction.info.transactionId')}</SCardLineLabel>
              <STransactionIdValue
                id="transaction-details-copy-trx-id"
                role="none"
                onClick={async (): Promise<void> => {
                  await navigator.clipboard.writeText(transactionData.id);
                  await messageApi.open({
                    type: 'success',
                    content: t('transaction.info.transactionId.copied'),
                    className: 'custom-success',
                    icon: <SuccessCircleIcon />,
                    style: {
                      marginTop: '80vh',
                    },
                  });
                }}
              >
                {contextHolder}
                {textTrimmingUtil(transactionData.id, 4, 4)}
                <CopyIcon />
              </STransactionIdValue>
            </SCardLine>
          )}
          {walletAddress && (
            <SCardLine>
              <SCardLineLabel>{t('transaction.info.walletAddress')}</SCardLineLabel>
              <STransactionIdValue
                id="transaction-details-copy-wallet-address"
                role="none"
                onClick={async (): Promise<void> => {
                  await navigator.clipboard.writeText(walletAddress);
                  await messageApi.open({
                    type: 'success',
                    content: t('transaction.info.walletAddress.copied'),
                    className: 'custom-success',
                    icon: <SuccessCircleIcon />,
                    style: {
                      marginTop: '80vh',
                    },
                  });
                }}
              >
                {contextHolder}
                {textTrimmingUtil(walletAddress, 4, 4)}
                <CopyIcon />
              </STransactionIdValue>
            </SCardLine>
          )}
        </SCard>

        <SSupportLinContainer>
          <SupportLink
            id="transaction-details-support"
            type={ESupportLinkType.Transaction}
            data={{
              transactionId: transactionData?.id,
              kind: transactionData?.kind,
              amount: transactionData?.amount,
              assetCode: transactionData?.asset_code,
              createdAt: transactionData?.created_at,
            }}
          />
        </SSupportLinContainer>

        <SButtonContainer>
          <Button id="transaction-details-wallet-btn" block onClick={onCloseHandler}>
            <Trans i18nKey="transaction.btn.close" />
          </Button>
          {isRefund && (
            <Button
              id="transaction-details-refund-btn"
              onClick={onRefundBtnHandler}
              styletype={EButtonStyleType.Secondary}
              block
              loading={isRefundBtnLoading}
            >
              <Trans i18nKey="transaction.btn.refund" />
            </Button>
          )}
          {transactionData?.state === ETransactionState.PostKycStarted && (
            <Button block onClick={onClickKycButtonHandler} styletype={EButtonStyleType.Secondary}>
              <Trans i18nKey="transaction.btn.redirect.to.kyc" />
            </Button>
          )}
        </SButtonContainer>
        <PoweredBy />
      </>
    </PullToRefresh>
  );
};

export default TransactionPage;
