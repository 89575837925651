import styled from 'styled-components';

export const STopContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const STransactionBlockchainIconWrapper = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: -4px;
  right: -4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.themeV3.colors.green200};
  border: 1px solid ${props => props.theme.themeV3.colors.grey100};

  svg {
    width: 14px;
    height: auto;
  }
`;

export const STransactionLogoContainer = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const STransactionLogo = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
`;

export const STopKind = styled.div`
  color: ${(props) => props.theme.themeV3.colors.grey700};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
`;

export const SAmount = styled.div`
  color: ${(props) => props.theme.themeV3.colors.grey700};
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`;

export const SNoData = styled.span`
  color: ${(props) => props.theme.themeV3.colors.grey300};
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`;

export const SGetAmount = styled.div`
  color: ${(props) => props.theme.themeV3.colors.grey400};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;

export const SCard = styled.div`
  margin-top: 20px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 16px;
  background: ${(props) => props.theme.themeV3.colors.grey200};
  color: ${(props) => props.theme.themeV3.colors.grey700};
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  
  hr {
    width: 100%;
    background-color: ${(props) => props.theme.themeV3.colors.grey300};
    height: 1px;
    border: 0;
  }
`;

export const SCardLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const SCardLineLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  
  svg {
    path {
      stroke: ${(props) => props.theme.themeV3.colors.grey400};
    }
  }
`;

export const SInfoIconContainer = styled.div`
  padding-left: 8px;
  margin-bottom: -6px;
`;

export const STransactionIdValue = styled.div`
  cursor: pointer;
  text-align: right;

  svg {
    width: 13px;
    height: 15px;
    margin-left: 8px;
    margin-bottom: -2px;

    path {
      stroke: ${(props) => props.theme.themeV3.colors.grey400};
    }
  }
`;

export const SButtonContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
`;

export const SSupportLinContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SWarningLabel = styled.div`
  color: ${(props) => props.theme.themeV3.colors.yellow500};
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  
  strong {
    font-weight: 800;
  }
`;

export const SInfiniteScrollLoader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;

  svg {
    path {
      fill: #00CC70;
    }
  }
`;
