export enum ELocalStorageKeys {
  TransactionGUID = 'transactionGUID',
  TransactionFilters = 'transactionFilters',
  CountryResidence = 'countryResidence',
  Theme = 'theme',
  ThemeSelector = 'themeSelector',
  UserEmail = 'userEmail',
  TransactionSignature = 'transactionSignature',
  OrderFormData = 'orderFormData',
}
