import { Button } from 'antd';
import styled from 'styled-components';

export const SButton = styled(Button)`
  padding: 13px 16px;
  border-radius: 6px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: none !important;
  color: ${props => props.theme.themeV3.colors.grey700};
  background: ${props => props.theme.themeV3.colors.grey200};
  font-size: 14px;
  font-weight: 500;
  font-family: "Plus Jakarta Sans", sans-serif;
  
  &:hover,
  &:active {
    background: ${props => props.theme.themeV3.colors.grey300} !important;
    color: ${props => props.theme.themeV3.colors.grey700} !important;
  }
  
  &:disabled {
    color: ${props => props.theme.themeV3.colors.grey300} !important;
    background: ${props => props.theme.themeV3.colors.grey200}!important;
    border: 0;
    
    svg {
      opacity: 0.2;
    }
  }
`;
