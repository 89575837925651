import { lazy } from 'react';
import { wrapCreateBrowserRouter } from '@sentry/react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from 'react-router-dom';
import ErrorBoundary from 'common/components/error-boundary/error-boundary.component.tsx';
import LazyComponent from 'common/components/lazy-component/lazy-component.component.tsx';
import MainLayout from 'common/layouts/main/main.layout.tsx';
import LoginGooglePage from 'pages/auth/login/login-google.page.tsx';
import { loginPageLoader } from 'pages/auth/login/login.page.loader.ts';
import LoginPage from 'pages/auth/login/login.page.tsx';
import OtpPage from 'pages/auth/otp/otp.page.tsx';
import { billingDetailsPageLoader } from 'pages/billing-details/billing-details.page.loader.ts';
import DecisionFlowPage from 'pages/decision-flow/decision-flow.page.tsx';
import { errorPageLoader } from 'pages/error/error.page.loader.ts';
import ErrorPage from 'pages/error/error.page.tsx';
import { finalStepPageLoader } from 'pages/final-step/final-step.page.loader.ts';
import FinalStepPage from 'pages/final-step/final-step.page.tsx';
import FpfOrderFormPage from 'pages/fpf-order-form/fpf-order-form.page.tsx';
import FpfPaymentPage from 'pages/fpf-payment/fpf-payment.page.tsx';
import { InteractiveFlowPage } from 'pages/interactive-flow/interactive-flow.page.tsx';
import { kycPageLoader } from 'pages/kyc/kyc.page.loader.ts';
import KycPage from 'pages/kyc/kyc.page.tsx';
import { NoMatchPage } from 'pages/no-match/no-match.page.tsx';
import TermsAndPrivacyPage from 'pages/terms-and-privacy/terms-and-privacy.page.tsx';
import TestSentryErrorPage from 'pages/test-sentry-error/test-sentry-error.page.tsx';
import { transactionPageLoader } from 'pages/transaction/transaction.page.loader.ts';
import TransactionPage from 'pages/transaction/transaction.page.tsx';
import { transactionsPageLoader } from 'pages/transactions/transactions.page.loader.ts';
import TransactionsPage from 'pages/transactions/transactions.page.tsx';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import NeutralRouteGuard from 'navigation/guards/NeutralRoute.guard.tsx';
import { neutralRouteGuardRootLoader } from 'navigation/guards/neutralRouteGuardRootLoader.loader.tsx';
import ProtectedRouteGuard from './guards/ProtectedRoute.guard.tsx';
import PublicRouteGuard from './guards/PublicRoute.guard.tsx';

// Disabled in case of the issue https://github.com/ArnaudBarre/eslint-plugin-react-refresh/issues/25
// eslint-disable-next-line react-refresh/only-export-components
const BillingDetailsPage = lazy(() => import('pages/billing-details/billing-details.page.tsx'));

const routes = (
  <Route
    path={ROUTES_MAPPING.ROOT}
    // This option allows you to unlock the React Query cache for a second request
    shouldRevalidate={({ currentUrl }): boolean => currentUrl.pathname === ROUTES_MAPPING.PUBLIC.LOGIN}
    errorElement={<ErrorBoundary />}
  >
    <Route
      element={<NeutralRouteGuard />}
      loader={neutralRouteGuardRootLoader}
    >
      <Route index element={<Navigate to={ROUTES_MAPPING.PUBLIC.LOGIN} />} />

      {/* Neutral routes */}
      <Route element={<MainLayout />}>
        <Route
          path={ROUTES_MAPPING.PUBLIC.TERMS_AND_CONDITIONS}
          element={<TermsAndPrivacyPage
            url="https://aps.money/sep-24-terms-conditions"
            title="termsAndConditions.title"
          />}
        />
        <Route
          path={ROUTES_MAPPING.PUBLIC.PRIVACY_AND_COOKIES_POLICY}
          element={<TermsAndPrivacyPage
            url="https://aps.money/sep-24-privacy-policy"
            title="privacyAndCookiesPolicy.title"
          />}
        />
        <Route path={ROUTES_MAPPING.DECISION_FLOW} element={<DecisionFlowPage />} />
        <Route path={ROUTES_MAPPING.FPF_ORDER_FORM} element={<FpfOrderFormPage />} />
        <Route path={ROUTES_MAPPING.TEST_SENTRY_ERROR} element={<TestSentryErrorPage />} />
      </Route>
      <Route path={ROUTES_MAPPING.FINAL_STEP} element={<FinalStepPage />} loader={finalStepPageLoader} />

      {/* Public routes */}
      <Route element={<PublicRouteGuard />}>
        <Route element={<MainLayout />}>
          <Route path={ROUTES_MAPPING.PUBLIC.LOGIN} element={<LoginPage />} loader={loginPageLoader} />
          <Route path={ROUTES_MAPPING.PUBLIC.LOGIN_GOOGLE} element={<LoginGooglePage />} loader={loginPageLoader} />
          <Route path={ROUTES_MAPPING.PUBLIC.OTP_CODE_VERIFICATION} element={<OtpPage />} />
        </Route>
      </Route>
      {/* Protected routes */}
      <Route element={<ProtectedRouteGuard />}>
        <Route element={<MainLayout />}>
          <Route path={ROUTES_MAPPING.PROTECTED.INTERACTIVE_FLOW} element={<InteractiveFlowPage />} />
          <Route
            path={ROUTES_MAPPING.PROTECTED.BILLING_DETAILS}
            element={
              <LazyComponent>
                <BillingDetailsPage />
              </LazyComponent>
            }
            loader={billingDetailsPageLoader}
          />
          <Route
            path={ROUTES_MAPPING.PROTECTED.KYC}
            element={
              <LazyComponent>
                <KycPage />
              </LazyComponent>
            }
            loader={kycPageLoader}
          />
          <Route path={ROUTES_MAPPING.PROTECTED.FPF_PAYMENT} element={<FpfPaymentPage />} />
          <Route
            path={ROUTES_MAPPING.PROTECTED.TRANSACTIONS}
            element={<TransactionsPage />}
            loader={transactionsPageLoader}
          />
          <Route
            path={ROUTES_MAPPING.PROTECTED.TRANSACTION}
            element={<TransactionPage />}
            loader={transactionPageLoader}
          />
        </Route>
      </Route>

      <Route element={<MainLayout />}>
        <Route path={ROUTES_MAPPING.ERROR} element={<ErrorPage />} loader={errorPageLoader} />
      </Route>

      <Route path={ROUTES_MAPPING.NO_MATCH} element={<MainLayout />}>
        <Route index element={<NoMatchPage />} />
      </Route>
      <Route path="*" element={<Navigate to={ROUTES_MAPPING.NO_MATCH} />} />
    </Route>
  </Route>
);

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
const routerTree = sentryCreateBrowserRouter(createRoutesFromElements(routes));

export default routerTree;
