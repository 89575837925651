import styled from 'styled-components';

export const SLogoWrapper = styled.div`
  padding: 16px 0;
`;

export const STitle = styled.h2`
  margin-top: 24px;
`;

export const SDescription = styled.p`
  margin-top: 8px;
`;

export const SDescriptionStartNewLoginHandler = styled.span`
  font-weight: 600;
  color: ${props => props.theme.themeV3.colors.green400};
  cursor: pointer;
`;

export const SFormWrapper = styled.div`
  margin-top: 24px;
`;

export const SOrDivider = styled.div`
  padding: 4px 0;
  margin: 24px 0 0;
  color: ${props => props.theme.themeV3.colors.grey300};
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
  
  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background: ${props => props.theme.themeV3.colors.grey300};
    display: block;
  }
`;

export const SOidcBtnWrapper = styled.div`
  margin-top: 24px;
`;

export const STermsConditions = styled.div`
  text-align: center;
  padding: 32px 0 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  font-variant-numeric: lining-nums tabular-nums;
  color: ${props => props.theme.themeV3.colors.grey400};
`;

export const SSecureStoredContainer = styled.p`
  color: ${(props) => props.theme.themeV3.colors.grey400} !important;
  display: flex;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: -8px;

  svg {
    path {
      stroke: ${(props) => props.theme.themeV3.colors.grey400};
      stroke-width: 1.5;
    }
  }
`;

export const SButtonWrapper = styled.div`
  margin-top: auto;
`;
