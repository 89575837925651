import { ReactElement, useEffect, useState } from 'react';
import { Form } from 'antd';
import { endOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Sheet } from 'react-modal-sheet';
import Button from 'common/components/button/button.component.tsx';
import { EButtonStyleType } from 'common/components/button/button.conmponent.enums.ts';
import ChipsGroup from 'common/components/chips-group/chips-group.component.tsx';
import DatePicker from 'common/components/date-picker/date-picker.component.tsx';
import { ELocalStorageKeys } from 'common/enums/localStorage.enums.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import {
  ETransactionsDataParams,
  ETransactionStatus,
} from 'domains/transaction/enums/transaction.enums.ts';
import {
  useCountTransactionsDataQuery,
} from 'domains/transaction/queries/transaction.query.ts';
import { TTransactionsCountReqParams } from 'domains/transaction/types/transaction.type.ts';
import { IUserWalletsDataRes } from 'domains/wallet/inrefaces/wallet.interfaces.ts';
import {
  FILTER_STATUS_MAPPING,
  FILTER_TYPE_MAPPING,
  FILTER_NETWORK_MAPPING,
  FILTER_PENDING_STATUS_MAPPING,
} from 'pages/transactions/constants/transactions.constants.ts';
import getFilterParamsUtil from 'pages/transactions/utils/get-filter-params.util.ts';
import CloseIcon from 'assets/close.icon.svg?react';
import {
  SButtonContainer,
  SFormItem,
  SSheet,
  SSheetTitle,
  SFilterItem,
  SFilterItemLabel,
  SSheetHeader,
  SCloseIconWrapper,
  SButtonContainerInnerWrapper,
} from './filter-bottom-sheet.cmponent.styles.ts';

interface IFields {
  [ETransactionsDataParams.Kind]: string;
  [ETransactionsDataParams.Status]: string;
  [ETransactionsDataParams.Blockchain]: string;
  [ETransactionsDataParams.WalletId]: string;
  [ETransactionsDataParams.StartCreatedAt]: number;
  [ETransactionsDataParams.EndCreatedAt]: number;
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  walletsData: IUserWalletsDataRes | Record<string, never>;
  filtersDataHandler: (params: TTransactionsCountReqParams) => void;
}

const FilterBottomSheetComponent = (props: IProps): ReactElement => {
  const {
    isOpen,
    onClose,
    filtersDataHandler,
    walletsData,
  } = props;

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [
    defaultFiltersParams,
    setDefaultFiltersParams
  ] = useState<TTransactionsCountReqParams | null>(null);

  const filterStatusOptions = FILTER_STATUS_MAPPING.map(filter => ({
    ...filter,
    label: t(filter.label),
  }));

  const filterTypeOptions = FILTER_TYPE_MAPPING.map(filter => ({
    ...filter,
    label: t(filter.label),
  }));

  const {
    data: countTransactionsData,
    isLoading: isCountTransactionsDataLoading,
    isError: isCountTransactionsDataError,
    error: countTransactionsDataError,
  } = useCountTransactionsDataQuery(
    defaultFiltersParams ?? {},
    {
      enabled: !!defaultFiltersParams,
    });

  useEffect(() => {
    if (isCountTransactionsDataError) {
      captureError(countTransactionsDataError as IError);
    }
  }, [isCountTransactionsDataError, countTransactionsDataError]);

  useEffect(() => {
    if (isOpen) {
      const initialFilterParams = getFilterParamsUtil(window.location.href);
      const filtersToArray = Object.entries(initialFilterParams) as [ETransactionsDataParams, string | number][];
      const defaultFormFieldsValue = filtersToArray.reduce((acc, [key, value]) => {
        const item: Partial<Record<ETransactionsDataParams, string[] | Date>> = {};

        if (
          key === ETransactionsDataParams.StartCreatedAt
          || key === ETransactionsDataParams.EndCreatedAt
        ) {
          item[key] = new Date(Number(value) * 1000);
        } else if (key === ETransactionsDataParams.Status) {
          const valueArray = (value as string).split(',') as ETransactionStatus[];
          const matchingItems = valueArray.filter(item => FILTER_PENDING_STATUS_MAPPING.includes(item)).join(',');
          const itemValue = new Set<string>([]);

          valueArray.forEach(item => {
            itemValue.add(FILTER_PENDING_STATUS_MAPPING.includes(item) ? matchingItems : item);
          });

          item[key] = [...itemValue];

        } else if (typeof value === 'string') {
          item[key] = value.split(',');
        }

        return {
          ...acc,
          ...item,
        };
      }, {});

      form.setFieldsValue(defaultFormFieldsValue);
    }
  }, [
  form,
  isOpen,
]);

  const convertFiltersToReqData = (
    values: Partial<IFields>
  ): TTransactionsCountReqParams => {
    const {
      kind,
      status,
      blockchain,
      wallet_id: walletId,
      start_created_at: startCreatedAt,
      end_created_at: endCreatedAt,
    } = values;

    return {
      ...(kind ? { kind } : {}),
      ...(status ? { status } : {}),
      ...(walletId ? { wallet_id: walletId } : {}),
      ...(blockchain ? { blockchain } : {}),
      ...(startCreatedAt ? { start_created_at: Date.parse(startCreatedAt as unknown as string) / 1000 } : {}),
      ...(endCreatedAt ? { end_created_at: Date.parse(endCreatedAt as unknown as string) / 1000 } : {}),
    } as TTransactionsCountReqParams;
  };

  const onFormChange = (values: IFields): void => {
    const filtersData = convertFiltersToReqData(values);
    const transactionParamsData = {
      ...filtersData,
      ...(filtersData.status ? { [ETransactionsDataParams.Status]: (filtersData.status ?? []).toString(), } : {}),
      ...(filtersData.kind ? { [ETransactionsDataParams.Kind]: (filtersData.kind ?? []).toString(), } : {}),
    };

    setDefaultFiltersParams(transactionParamsData);
  };

  const setFilterItem = (values: Partial<IFields> | Record<string, never>): void => {
    const filtersData = convertFiltersToReqData(values);

    // Set data to local storage
    localStorage.setItem(ELocalStorageKeys.TransactionFilters, JSON.stringify(filtersData));

    // Set data to URL query params
    const url = new URL(window.location.origin + window.location.pathname);
    Object.entries(filtersData).forEach(([key, value]) => {
      url.searchParams.set(key, Array.isArray(value) ? value.toString() : value);
    });
    window.history.pushState(null, '', url.toString());
  };

  const resetBtnHandler = (): void => {
    const defaultStatusValue = [
      [
        ETransactionStatus.PendingUserTransferStart,
        ETransactionStatus.PendingAnchor,
        ETransactionStatus.PendingExternal,
      ].toString(),
      ETransactionStatus.Error,
      ETransactionStatus.Completed,
      ETransactionStatus.Refunded,
      ETransactionStatus.PendingUser,
      ETransactionStatus.Expired,
    ];

    setFilterItem({
      [ETransactionsDataParams.Status]: defaultStatusValue.toString(),
    });
    form.resetFields();
    form.setFields([
      {
        name: ETransactionsDataParams.Status,
        value: defaultStatusValue,
      },
    ]);
    setDefaultFiltersParams({
      [ETransactionsDataParams.Status]: defaultStatusValue.toString(),
    });
  };

  const formSubmitHandler = (values: IFields): void => {
    setFilterItem(values);
    const filtersData = convertFiltersToReqData(values);
    filtersDataHandler(filtersData);
    onClose();
  };

  return (
    <SSheet
      isOpen={isOpen}
      onClose={onClose}
      snapPoints={[0.95, 0]}
      initialSnap={0}
    >
      <Sheet.Container>
        <SSheetHeader>
          <SSheetTitle>{t('transactions.bottomSheet.filters.title')}</SSheetTitle>
          <SCloseIconWrapper onClick={onClose}>
            <CloseIcon />
          </SCloseIconWrapper>
        </SSheetHeader>
        <Sheet.Content>
          <Sheet.Scroller draggableAt="both">
            <Form
              layout="vertical"
              style={{ flex: 1 }}
              form={form}
              onFinish={formSubmitHandler}
              onValuesChange={(_, allFields: IFields) => {
                onFormChange(allFields);
              }}
            >
              <SFilterItem>
                <SFilterItemLabel>{t('transactions.bottomSheet.filters.status.label')}</SFilterItemLabel>
                <SFormItem
                  name={ETransactionsDataParams.Status}
                >
                  <ChipsGroup options={filterStatusOptions} />
                </SFormItem>
              </SFilterItem>

              <SFilterItem>
                <SFilterItemLabel>{t('transactions.bottomSheet.filters.type.label')}</SFilterItemLabel>
                <SFormItem
                  name={ETransactionsDataParams.Kind}
                >
                  <ChipsGroup options={filterTypeOptions} />
                </SFormItem>
              </SFilterItem>

              <SFilterItem>
                <SFilterItemLabel>{t('transactions.bottomSheet.filters.wallet.label')}</SFilterItemLabel>
                <SFormItem
                  name={ETransactionsDataParams.WalletId}
                >
                  <ChipsGroup
                    options={(walletsData.wallets ?? []).map(wallet => ({
                      label: wallet.name,
                      value: wallet.id,
                    }))}
                  />
                </SFormItem>
              </SFilterItem>

              <SFilterItem>
                <SFilterItemLabel>{t('transactions.bottomSheet.filters.network.label')}</SFilterItemLabel>
                <SFormItem
                  name={ETransactionsDataParams.Blockchain}
                >
                  <ChipsGroup options={FILTER_NETWORK_MAPPING} />
                </SFormItem>
              </SFilterItem>

              <SFilterItem>
                <SFilterItemLabel>{t('transactions.bottomSheet.filters.dateRange.label')}</SFilterItemLabel>
                <SFormItem
                  name={ETransactionsDataParams.StartCreatedAt}
                  label={t('transactions.bottomSheet.filters.dateRange.start.label')}
                >
                  <DatePicker
                    inputReadOnly
                    placeholder={t('transactions.bottomSheet.filters.dateRange.start.placeholder')}
                    showNow={false}
                    disabledDate={(current: Date) => current > endOfDay(new Date())}
                  />
                </SFormItem>
                <SFormItem
                  name={ETransactionsDataParams.EndCreatedAt}
                  label={t('transactions.bottomSheet.filters.dateRange.end.label')}
                >
                  <DatePicker
                    inputReadOnly
                    placeholder={t('transactions.bottomSheet.filters.dateRange.end.placeholder')}
                    showNow={false}
                    disabledDate={(current: Date) => current > endOfDay(new Date())}
                  />
                </SFormItem>
              </SFilterItem>

              <SButtonContainer>
                <SButtonContainerInnerWrapper>
                  <Button
                    onClick={resetBtnHandler}
                    styletype={EButtonStyleType.Secondary}
                    block
                  >
                    {t('transactions.bottomSheet.filters.btn.reset')}
                  </Button>
                  <Button
                    block
                    htmlType="submit"
                    loading={isCountTransactionsDataLoading}
                    disabled={countTransactionsData?.count === 0}
                  >
                    {t('transactions.bottomSheet.filters.btn.showResults', {
                      count: countTransactionsData?.count ?? 0
                    })}
                  </Button>
                </SButtonContainerInnerWrapper>
              </SButtonContainer>
            </Form>
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop
        onTap={() => {
          onClose();
        }}
      />
    </SSheet>
  );
};

export default FilterBottomSheetComponent;
