import { Button } from 'antd';
import styled, { css, Interpolation } from 'styled-components';
import { EButtonStyleType } from 'common/components/button/button.conmponent.enums.ts';
import { IButtonProps } from 'common/components/button/button.conmponent.interfaces.ts';
import { EThemes } from 'theme/enums/theme.enums.ts';

export const SButton = styled(Button)<IButtonProps & { themeName: EThemes }>`
  height: 56px;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: none !important;
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.themeName === EThemes.Dark 
      ? props.theme.themeV3.colors.grey300 
      : props.theme.themeV3.colors.grey700
  } !important;
  
  .ant-btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &.ant-btn-loading {
    background: ${props => props.theme.themeV3.colors.grey200} !important;
    color: ${props => props.theme.themeV3.colors.grey300} !important;
    border: 0;
    
    &:hover {
      background: ${props => props.theme.themeV3.colors.grey200} !important;
      color: ${props => props.theme.themeV3.colors.grey300} !important;
    }
    
    span {
      font-size: 24px;
      color: #5FCF80;
    }
  }
  
  ${(props): Interpolation<object>[] => {
    switch (props.styletype) {
      case EButtonStyleType.Secondary:
        return css`
          background: ${props => props.theme.themeV3.colors.grey100} !important;
          border: 1px solid ${props => props.theme.themeV3.colors.green300};
        `;

      default:
        return css`
          background: ${props => props.theme.themeV3.colors.green300} !important;
        `;
    }
  }}
  
  &:hover,
  &:active {
    ${(props): Interpolation<object>[] => {
      switch (props.styletype) {
        case EButtonStyleType.Secondary:
          return css`
            background: ${props => props.theme.themeV3.colors.grey200} !important;
            color: ${props => props.theme.themeV3.colors.grey700};
        `;

        default:
          return css`
            background: ${props => props.theme.themeV3.colors.green200} !important;
            color: ${props => props.theme.themeV3.colors.grey700};
        `;
      }
    }}
  }

  &:disabled {
    ${(props): Interpolation<object>[] => {
      switch (props.styletype) {
        case EButtonStyleType.Secondary:
          return css`
            border: 1px solid ${props => props.theme.themeV3.colors.grey200} !important;
            background: ${props => props.theme.themeV3.colors.grey100} !important;
            color: ${props => props.theme.themeV3.colors.grey300} !important;
        `;

        default:
          return css`
            background: ${props => props.theme.themeV3.colors.grey200} !important;
            color: ${props => props.theme.themeV3.colors.grey300} !important;
            border: 0;
        `;
      }
    }}
  }
`;
