import { ReactElement } from 'react';
import { useAtom } from 'jotai';
import { SButton } from 'common/components/button/button.component.styles.ts';
import { IButtonProps } from 'common/components/button/button.conmponent.interfaces.ts';
import { themeNameAtom } from 'common/stores/theme.store.ts';

const Button = (props: IButtonProps): ReactElement => {
  const { children, loading } = props;
  const [themeName] = useAtom(themeNameAtom);

  return (
    <SButton
      type="primary"
      themeName={themeName}
      {...props}
    >
      {!loading && children}
    </SButton>
  );
};

Button.defaultProps = {
  styletype: undefined,
};

export default Button;
