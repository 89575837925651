import { ELocalStorageKeys } from 'common/enums/localStorage.enums.ts';
import { IOrderFormData } from 'domains/transaction/interfaces/order-form.interfaces.ts';

export const saveOrderFormData = (data: IOrderFormData): void => {
  localStorage.setItem(ELocalStorageKeys.OrderFormData, JSON.stringify(data));
};

export const getOrderFormData = (): IOrderFormData | null => {
  try {
    const dataFromLocalStore = localStorage.getItem(ELocalStorageKeys.OrderFormData) ?? '';

    return JSON.parse(dataFromLocalStore) as IOrderFormData;
  } catch (error) {
    return null;
  }
};
