import styled from 'styled-components';

export const STitle = styled.h2`
  margin-top: 24px;
`;

export const SDescription = styled.p`
  margin-top: 4px;
`;

export const SOtpDescription = styled.div`
  color: ${props => props.theme.themeV3.colors.grey700};
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-top: 24px;
`;

export const SResendCodeTile = styled.div`
  color: ${props => props.theme.themeV3.colors.grey400};
  font-variant-numeric: lining-nums tabular-nums;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;

export const SResendCodeBtn = styled(SResendCodeTile)`
  color: ${props => props.theme.themeV3.colors.green400};
  cursor: pointer;
`;

export const SResendFormWrapper = styled.div`
  text-align: left;
  margin-top: 8px;
  
  .ant-form-item {
    margin-bottom: 8px;
  }

  .ant-form-item-explain {
    display: none;
  }
`;

export const SBottomContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
`;
