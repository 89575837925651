import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { isWebview } from 'common/utils/web-view.utils.ts';

export interface IError extends Error, AxiosError {
  config: {
    baseURL: string;
    url: string;
    method: string;
    data: string;
  } & AxiosError['config'];
  response?: {
    data?: {
      error?: string;
    }
  } & AxiosError['response'];
}

export const captureError = (error: IError): void => {
  if (error.response) {
    const {
      message,
      config: {
        baseURL,
        url,
        method,
      },
      response: {
        status,
        data: responseData,
        headers,
      },
    } = error;

    console.error(error);

    const errorAdditionalData = {
      status,
      responseData,
      url: `${baseURL}/${url}`,
      method,
      headers: {...headers},
      errorMessage: message,
      isWebview: isWebview(),
    };

    Sentry.withScope((scope) => {
      scope.setLevel('error');
      scope.setContext('Error additional data', errorAdditionalData);
      scope.captureMessage(`Request Error: [${method} ${status}] ${responseData?.error ?? ''}`);
    });
  } else if (error.request) {
    const {
      message,
      config: {
        baseURL,
        url,
        method,
      },
    } = error;

    console.error(error);

    const errorAdditionalData = {
      url: `${baseURL}/${url}`,
      method,
      errorMessage: message,
    };

    Sentry.setContext('Error additional data', errorAdditionalData);
    Sentry.captureException(error);
  } else {
    console.error(error);

    Sentry.captureException(error);
  }
};
