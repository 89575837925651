import { ReactElement, ReactNode, useState, useEffect } from 'react';
import { format, parseISO, parse } from 'date-fns';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { Loader } from 'common/components/loader/loader.component.tsx';
import PoweredBy from 'common/components/powered-by/powered-by.component.tsx';
import {
  ETransactionStatusBadgeType
} from 'common/components/transaction-status-badge/enums/transaction-status-badge.enums.ts';
import TransactionStatusBadge from 'common/components/transaction-status-badge/transaction-status-badge.component.tsx';
import { headerConfigAtom } from 'common/stores/header.store.ts';
import { amountDigitSeparator } from 'common/utils/digit-separator.utils.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { BLOCKCHAIN_NETWORK_MAPPING } from 'domains/transaction/constants/transaction.constants.tsx';
import { ETransactionKind, ETransactionsDataParams } from 'domains/transaction/enums/transaction.enums.ts';
import {
  ITransaction,
  ITransactionsDataParams,
  ITransactionsDataRes,
} from 'domains/transaction/interfaces/transaction.interfaces.ts';
import { useGetTransactionsQuery } from 'domains/transaction/queries/transaction.query.ts';
import { TTransactionsCountReqParams } from 'domains/transaction/types/transaction.type.ts';
import { WALLETS_MAPPING } from 'domains/wallet/constants/wallet.constants.tsx';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import FilterIcon from 'assets/filter.icon.svg?react';
import SearchIcon from 'assets/search.icon.svg?react';
import DefaultMerchantIcon from 'assets/wallet-logo-icons/default.icon.svg?react';
import FilterBottomSheetComponent from './components/filter-bottom-sheet/filter-bottom-sheet.component.tsx';
import { ITransactionsPageLoaderData } from './interfaces/transactions.interfaces.ts';
import {
  SContentWrapper,
  SGroupDate,
  STransaction,
  STransactionAmount,
  STransactionBlockchainIconWrapper,
  STransactionDate,
  STransactionKind,
  STransactionLastColumn,
  STransactionLogo,
  STransactionLogoContainer,
  STransactionMiddleColumn,
  STransactionsContainer,
  STopBar,
  SFilterIconWrapper,
  SInfiniteScrollLoader,
  STitle,
  SBottomContainer,
  SNoTrxDataDescription,
  SNoTrxDataTitle,
  SNoTrxDataContainer,
  SNoDataInfo,
} from './transactions.styles.ts';

const TransactionsPage = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const setHeaderConfig = useSetAtom(headerConfigAtom);
  const {
    walletsData,
    transactionsData: transactionsDataFromLoader,
    initialParams,
  } = useLoaderData() as ITransactionsPageLoaderData;
  const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false);
  const [getTransactionsParams, setGetTransactionsParams] = useState<ITransactionsDataParams | null>(null);
  const PAGE_LIMIT = 20;

  const {
    data: transactionsData,
    isLoading: isTransactionsDataLoading,
    isFetching: isTransactionsDataFetching,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch: refetchTransactionsData,
    isError: isTransactionsDataError,
    error: transactionsDataError,
  } = useGetTransactionsQuery({
    reqParams: {
      ...(getTransactionsParams ?? initialParams),
      limit: PAGE_LIMIT,
    },
    queryOptions: {
      ...(transactionsDataFromLoader ? { initialData: transactionsDataFromLoader } : {}),
      initialPageParam: 0,
      getNextPageParam: (lastPage: ITransactionsDataRes) => {
        const { total, offset } = lastPage;
        const nextOffset = offset + PAGE_LIMIT;
        return total <= nextOffset ? undefined : nextOffset;
      },
      refetchOnMount: false,
    },
  });

  useEffect(() => {
    if (isTransactionsDataError) {
      captureError(transactionsDataError as IError);
    }
  }, [isTransactionsDataError, transactionsDataError]);

  useEffect(() => {
    setHeaderConfig({
      title: 'transactions.title',
    });
  }, [setHeaderConfig]);

  const filtersDataHandler = (params: TTransactionsCountReqParams): void => {
    const transactionParamsData = {
      ...params,
      ...(params.status ? { [ETransactionsDataParams.Status]: (params.status ?? []).toString(), } : {}),
      ...(params.kind ? { [ETransactionsDataParams.Kind]: (params.kind ?? []).toString(), } : {}),
    } as ITransactionsDataParams;
    setGetTransactionsParams(transactionParamsData);
  };

  const allTransactionsDataItems = (transactionsData?.pages ?? []).flatMap((page) => page.transactions);

  const groupByData = allTransactionsDataItems.reduce(
    (groups: Record<string, never> | Record<string, ITransaction[]>, item) => {
      const parsedDate = parseISO(item.created_at);
      const formattedDate = format(parsedDate, 'yyyy-MM');

      return {
        ...groups,
        ...{
          [formattedDate]: [...(groups[formattedDate] ?? []), item],
        },
      };
    },
    {},
  );

  const renderTransaction = (transaction: ITransaction): ReactNode => {
    const parsedDate = parseISO(transaction.created_at);
    const formattedCreatedAtDate = format(parsedDate, 'MMM d · h:mm a');

    const amount = transaction.amount_in !== '0'
      ? transaction.amount_in
      : transaction.amount_out ?? '';

    const asset = (transaction.amount_in_asset || '').split(':')[1]
      || (transaction.amount_out_asset ?? '').split(':')[1];

    const isNoData = !amount || amount === '0';

    return (
      <STransaction
        id="transactions-trx-record"
        key={transaction.id}
        onClick={() => {
          navigate(`${ROUTES_MAPPING.PROTECTED.TRANSACTION}?id=${transaction.id}`);
        }}
      >
        <STransactionLogoContainer>
          <STransactionLogo>
            {transaction.wallet.key in WALLETS_MAPPING ? (
              WALLETS_MAPPING[transaction.wallet.key].icon
            ) : (
              <DefaultMerchantIcon />
            )}
          </STransactionLogo>
          {transaction.blockchain in BLOCKCHAIN_NETWORK_MAPPING && (
            <STransactionBlockchainIconWrapper>
              {BLOCKCHAIN_NETWORK_MAPPING[transaction.blockchain].icon}
            </STransactionBlockchainIconWrapper>
          )}
        </STransactionLogoContainer>
        <STransactionMiddleColumn>
          <STransactionKind>{transaction.kind}</STransactionKind>
          <STransactionDate>{formattedCreatedAtDate}</STransactionDate>
        </STransactionMiddleColumn>
        <STransactionLastColumn>
          <STransactionAmount>
            {isNoData ? (
              <SNoDataInfo>{t('transaction.info.noData')} </SNoDataInfo>
            ) : (
              <>
                {Number(transaction.amount_in) !== 0 && (transaction.kind === ETransactionKind.Deposit ? '+' : '-')}
                {amountDigitSeparator(amount)} {asset}
              </>
            )}
          </STransactionAmount>
          <TransactionStatusBadge type={ETransactionStatusBadgeType.Simple} transactionData={transaction} />
        </STransactionLastColumn>
      </STransaction>
    );
  };

  const renderGroupByData = (): ReactNode =>
    Object.entries(groupByData).map(([date, transactions]) => {
      const parsedDate = parse(date, 'yyyy-MM', new Date());
      const formattedMonth = format(parsedDate, 'MMMM yyyy');

      return (
        <div key={date}>
          <SGroupDate>{formattedMonth}</SGroupDate>
          {transactions.map((transaction: ITransaction) => renderTransaction(transaction))}
        </div>
      );
    });

  const transactionListRefreshHandler = async (): Promise<void> => {
    try {
      await refetchTransactionsData();
    } catch (error) {
      captureError(error as IError);
    }
  };

  const transactionListFetchMoreHandler = async (): Promise<void> => {
    try {
      if (hasNextPage && !isTransactionsDataFetching) {
        await fetchNextPage();
      }
    } catch (error) {
      captureError(error as IError);
    }
  };

  return (
    <>
      <SContentWrapper>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}>
          <PullToRefresh
            canFetchMore={!!allTransactionsDataItems.length}
            onRefresh={transactionListRefreshHandler}
            refreshingContent={
              <SInfiniteScrollLoader>
                <Loader />
              </SInfiniteScrollLoader>
            }
            onFetchMore={transactionListFetchMoreHandler}
            pullingContent={<> </>}
            fetchMoreThreshold={10}
          >
            <>
              <STopBar>
                <STitle>
                  {t('transactions.transactions.label')}
                </STitle>
                <SFilterIconWrapper
                  id="transactions-filter"
                  onClick={() => {
                    setIsOpenFilters(true);
                  }}
                >
                  {t('transactions.filter.label')}
                  <FilterIcon />
                </SFilterIconWrapper>
              </STopBar>
              {allTransactionsDataItems.length ? (
                <STransactionsContainer>{renderGroupByData()}</STransactionsContainer>
              ): (
                <SNoTrxDataContainer>
                  <SearchIcon />
                  <SNoTrxDataTitle>{t('transactions.transactions.notFound.title')}</SNoTrxDataTitle>
                  <SNoTrxDataDescription className="p2">
                    {t('transactions.transactions.notFound.description')}
                  </SNoTrxDataDescription>
                </SNoTrxDataContainer>
              )}
              {isFetchingNextPage && (
                <SInfiniteScrollLoader>
                  <Loader />
                </SInfiniteScrollLoader>
              )}
            </>
          </PullToRefresh>
        </div>
      </SContentWrapper>
      <FilterBottomSheetComponent
        isOpen={isOpenFilters}
        filtersDataHandler={filtersDataHandler}
        walletsData={walletsData}
        onClose={() => {
          setIsOpenFilters(false);
        }}
      />
      <SBottomContainer>
        <PoweredBy />
      </SBottomContainer>
      <Loader spinning={isTransactionsDataFetching || isTransactionsDataLoading} fullscreen />
    </>
  );
};

export default TransactionsPage;
