import { ReactElement, useState } from 'react';
import { decodeToken } from 'react-jwt';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { ELocalStorageKeys } from 'common/enums/localStorage.enums.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { amountDigitSeparator } from 'common/utils/digit-separator.utils.ts';
import { ETransactionFlowType, ETransactionKind } from 'domains/transaction/enums/transaction.enums.ts';
import { IOrderFormData } from 'domains/transaction/interfaces/order-form.interfaces.ts';
import { ITransactionInfoDataRes } from 'domains/transaction/interfaces/transaction.interfaces.ts';
import { getOrderFormData } from 'domains/transaction/utils/getOrderData.utils.ts';
import { SRateArrowIcon, SRatesContainer, SValue } from './rates.component.styles.ts';

const Rates = (): ReactElement | null => {
  const [orderFormData] = useState<IOrderFormData | null>(getOrderFormData);
  const transactionInfoQueriesData = queryClient.getQueriesData({
    queryKey: queryKeysClient[EQueryKeys.TransactionKeys].getGeneralTransactionInfoData._def,
  });
  const transactionSignature = localStorage.getItem(ELocalStorageKeys.TransactionSignature);

  let kind: ETransactionKind | undefined;
  let headerAsset = '';

  if (transactionInfoQueriesData[0]?.[1]) {
    const transactionData = transactionInfoQueriesData[0]?.[1] as ITransactionInfoDataRes;
    const assetData = transactionData.kind === ETransactionKind.Deposit
      ? transactionData.amount_out_asset
      : transactionData.amount_in_asset;
    ([, headerAsset] = assetData.split(':'));
    kind = transactionData.kind;
  } else if (transactionSignature) {
    const signatureData = decodeToken<{
      payload: {
        base_currency_amount: string;
        base_currency_code: string;
        quote_currency_code: string;
        flow_type: ETransactionFlowType;
      }
    }>(transactionSignature);

    if (signatureData?.payload) {
      const flowType = signatureData.payload.flow_type;
      const assetData = flowType === ETransactionFlowType.OnRamp
        ? signatureData.payload.quote_currency_code
        : signatureData.payload.base_currency_code;
      ([, headerAsset] = assetData.split(':'));
      kind = flowType === ETransactionFlowType.OnRamp ? ETransactionKind.Deposit : ETransactionKind.Withdrawal;
    }
  }

  let amountIn = '';
  let amountInAsset = '';

  let amountOut = '';
  let amountOutAsset = '';

  if (kind && orderFormData) {
    if (kind === ETransactionKind.Deposit) {
      amountIn = orderFormData.delivery_amount;
      const paymentMethodOrderFormData = orderFormData.paymentMethod.split(':');
      ([, , , amountInAsset] = paymentMethodOrderFormData);
      amountOut = orderFormData.you_get;
      amountOutAsset = headerAsset;
    } else {
      amountIn = orderFormData.delivery_amount;
      amountInAsset = headerAsset;
      amountOut = orderFormData.you_get;
      const paymentMethodOrderFormData = orderFormData.paymentMethod.split(':');
      ([, , , amountOutAsset] = paymentMethodOrderFormData);
    }
  }

  const isAmountIn = amountIn && amountInAsset;
  const isAmountOut = amountOut && amountOutAsset;

  if (isAmountIn || isAmountOut) {
    return <SRatesContainer>
      {isAmountIn && (
        <SValue>{amountIn && amountDigitSeparator(amountIn)} {amountInAsset}</SValue>
      )}
      {(isAmountIn && isAmountOut) && (
        <SRateArrowIcon />
      )}
      {isAmountOut && (
        <SValue>{amountOut && amountDigitSeparator(amountOut)} {amountOutAsset}</SValue>
      )}
    </SRatesContainer>;
  }

  return null;
};

export default Rates;
