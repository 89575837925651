import { ReactElement, useEffect, useState, useCallback, useRef } from 'react';
import { Session } from '@ory/kratos-client';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { decodeToken } from 'react-jwt';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { setSmartlookIdentity } from 'common/clients/smartlook.client.ts';
import PoweredBy from 'common/components/powered-by/powered-by.component.tsx';
import { API_ENDPOINTS } from 'common/constants/api-endpoints.constants.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { headerConfigAtom } from 'common/stores/header.store.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { EAction } from 'domains/auth/enums/auth.enums.ts';
import { lightWalkThroughInteractiveService } from 'domains/auth/services/auth.service.ts';
import { useOffRampMutation, useOnRampMutation } from 'domains/on-off-ramp/queries/on-off-ramp.query.ts';
import { ETransactionFlowType } from 'domains/transaction/enums/transaction.enums.ts';
import { IOrderFormData } from 'domains/transaction/interfaces/order-form.interfaces.ts';
import {
  useGetTransactionDataMutation,
} from 'domains/transaction/queries/transaction.query.ts';
import { getOrderFormData } from 'domains/transaction/utils/getOrderData.utils.ts';
import { useGetUserProfileDataMutation } from 'domains/user/queries/user.query.ts';
import { IWalletDataRes } from 'domains/wallet/inrefaces/wallet.interfaces.ts';
import { EErrorPageTypeError } from 'pages/error/enums/error.page.enums.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import ApsBigIcon from 'assets/logo-icons/aps-big-logo.icon.svg?react';
import {
  SBottomContainer,
  SContent,
  SPreloaderContainer,
  SPreloaderProgress,
  SLoadingText,
  SContentContainer,
} from './interactive-flow.page.styles.ts';

export const InteractiveFlowPage = (): ReactElement => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const effectRan = useRef(false);
  const [orderFormData] = useState<IOrderFormData | null>(getOrderFormData);
  const setHeaderConfig = useSetAtom(headerConfigAtom);
  const interactiveIdQueryParamValue = searchParams.get(EUrlQueryParams.InteractiveId) ?? '';
  const signatureQueryParamValue = searchParams.get(EUrlQueryParams.Signature) ?? '';
  const getToSession = queryClient.getQueryData(queryKeysClient[EQueryKeys.AuthQueryKeys].getToSession().queryKey);
  const { identity } = getToSession as Session;
  const { mutateAsync: mutateAsyncGetTransactionDataMutation } = useGetTransactionDataMutation();
  const { mutateAsync: mutateAsyncOnRampMutation } = useOnRampMutation();
  const { mutateAsync: mutateAsyncOffRampMutation } = useOffRampMutation();
  const { mutateAsync: mutateAsyncGetUserProfileDatMutation } = useGetUserProfileDataMutation();
  const [progress, setProgress] = useState<number>(0);
  const [requestStep, setRequestStep] = useState<number>(0);
  const { t } = useTranslation();
  const walletRawDataFromCache = queryClient.getQueriesData({
    queryKey: queryKeysClient[EQueryKeys.WalletKeys].getWalletData._def,
  });
  const walletDataFromCache = walletRawDataFromCache[0][1] as IWalletDataRes;
  const userIdentityId = identity?.id;
  const identityTraits = identity?.traits as { email?: string } | undefined;
  const userEmail = identityTraits?.email ?? '';

  useEffect(() => {
    setHeaderConfig({
      title: false,
      backLink: '',
      isMenuVisible: false,
    });
  }, [setHeaderConfig]);

  const loadingTextInfo = [
    'interactiveFlow.preLoader.resInfoText.start',
    'interactiveFlow.preLoader.resInfoText.next',
    'interactiveFlow.preLoader.resInfoText.billingFileds',
    'interactiveFlow.preLoader.resInfoText.kyc',
    'interactiveFlow.preLoader.resInfoText.fpf',
  ];

  const startInteractiveMainPath = `/sep0024/transactions/next/interactive/${interactiveIdQueryParamValue}`;

  const generateStartInteractiveQueryParams = (): string => {
    const queryParams = new URLSearchParams();
    queryParams.set(EUrlQueryParams.Redirect, 'false');
    queryParams.set(EUrlQueryParams.Amount, orderFormData?.you_get ?? '');
    queryParams.set(EUrlQueryParams.AmountIn, orderFormData?.delivery_amount ?? '');
    queryParams.set(EUrlQueryParams.PaymentMethod, orderFormData?.paymentMethod ?? '');

    return decodeURIComponent(queryParams.toString());
  };

  const startInteractive = useCallback(
    async (url: string): Promise<void> => {
      const { url: nextUrl, action } = await lightWalkThroughInteractiveService(url);
      setProgress((prevProgress) => prevProgress + 20);
      setRequestStep((prevStep) => prevStep + 1);

      if (nextUrl) {
        if (action === EAction.Finish) {
          const transactionData = await mutateAsyncGetTransactionDataMutation({
            sep24GUID: interactiveIdQueryParamValue,
          });

          setSmartlookIdentity({
            interactiveID: interactiveIdQueryParamValue,
            userIdentityId,
            userEmail,
            transactionData,
          });

          setProgress(100);

          setTimeout(() => {
            const redirectUrl = new URL(nextUrl);
            const redirectPath = redirectUrl.pathname + redirectUrl.search;

            navigate(redirectPath);
          }, 1000);
        } else {
          await startInteractive(`${nextUrl}?redirect=false`);
        }
      }
    },
    [
      interactiveIdQueryParamValue,
      mutateAsyncGetTransactionDataMutation,
      navigate,
      userEmail,
      userIdentityId
    ],
  );

  useEffect((): () => void => {
    if (!effectRan.current) {
      (async (): Promise<void> => {
        const profileData = await mutateAsyncGetUserProfileDatMutation({});

        setSmartlookIdentity({
          interactiveID: interactiveIdQueryParamValue,
          userIdentityId,
          userEmail,
          wallet_name: walletDataFromCache.name,
          kyc_status: profileData.info.kyc_status,
          kyc_level: profileData.info.kyc_level,
          user_city: profileData.info.user_city,
          user_country: profileData.info.country_residence,
        });

        if (interactiveIdQueryParamValue) {
          const interactiveURL = `${startInteractiveMainPath}?${generateStartInteractiveQueryParams()}`;
          await startInteractive(interactiveURL);
        } else if (signatureQueryParamValue) {
          setProgress((prevProgress) => prevProgress + 20);

          const signatureData = decodeToken<{
            payload: {
              flow_type: ETransactionFlowType;
            }
          }>(signatureQueryParamValue);
          const flowType = signatureData?.payload.flow_type;

          const reqData = {
            payment_method: (orderFormData?.paymentMethod ?? '').split(':').slice(1, 3).join(':'),
            base_currency_amount: orderFormData?.you_get ?? '',
            signature: signatureQueryParamValue,
          };

          let interactiveUrl: string;

          if (flowType === ETransactionFlowType.OnRamp) {
            ({ url: interactiveUrl } = await mutateAsyncOnRampMutation({ reqData }));
          } else {
            ({ url: interactiveUrl } = await mutateAsyncOffRampMutation({ reqData }));
          }

          if (interactiveUrl) {
            const url = `${interactiveUrl}?${generateStartInteractiveQueryParams()}`;
            await startInteractive(url);
          }
        }
      })().catch(err => {
        const error = err as IError;
        captureError(error);
        const errorData = error.response?.data;

        if (errorData?.error === 'can\'t process fpf interactive transaction: screening failed') {
          navigate({
            pathname: ROUTES_MAPPING.ERROR,
            search: createSearchParams({
              ...(interactiveIdQueryParamValue
                ? { [EUrlQueryParams.InteractiveId]: interactiveIdQueryParamValue }
                : {}),
              [EUrlQueryParams.Title]: 'interactiveFlow.error.title',
              [EUrlQueryParams.Message]: 'interactiveFlow.error.description',
            }).toString(),
          });
        } else if (
          error.response?.status === 409
          && error.response.config.method === 'post'
          && (
            error.response.config.url === API_ENDPOINTS.ON_OFF_RAMP.ON_RAMP
            || error.response.config.url === API_ENDPOINTS.ON_OFF_RAMP.OFF_RAMP
          )
        ) {
          navigate(ROUTES_MAPPING.ERROR, {
            state: {
              type: EErrorPageTypeError.LastPaymentInProgress
            }
          });
        } else {
          navigate(ROUTES_MAPPING.ERROR);
        }
      });
    }

    return (): void => {
      effectRan.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SContent>
      <SContentContainer>
        <SPreloaderContainer>
          <ApsBigIcon />
          <SPreloaderProgress style={{ width: `${progress}%` }}>
          <ApsBigIcon />
          </SPreloaderProgress>
        </SPreloaderContainer>
        <SLoadingText className="p2">{t(loadingTextInfo[requestStep])}</SLoadingText>
      </SContentContainer>
      <SBottomContainer>
        <PoweredBy />
      </SBottomContainer>
    </SContent>
  );
};
