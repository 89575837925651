
import React, { ReactElement, useEffect, useState } from 'react';
import { SessionAuthenticationMethodMethodEnum } from '@ory/kratos-client';
import { FrontendApiUpdateLoginFlowRequest, FrontendApiUpdateRegistrationFlowRequest } from '@ory/kratos-client/api.ts';
import { Form, message } from 'antd';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setSmartlookIdentity } from 'common/clients/smartlook.client.ts';
import FormItem from 'common/components/form-item/form-item.component.tsx';
import InputMask, { IInputMaskValue } from 'common/components/input-mask/input-mask.component.tsx';
import PoweredBy from 'common/components/powered-by/powered-by.component.tsx';
import Rates from 'common/components/rates/rates.component.tsx';
import Steps from 'common/components/steps/steps.component.tsx';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { setHttps } from 'common/utils/url.utils.ts';
import {
  authGetLoginFlowService, authGetRegistrationFlowService,
  authUpdateLoginFlowService,
  authUpdateRegistrationFlowService,
} from 'domains/auth/services/auth.service.ts';
import { ISubmitCatchError } from 'pages/auth/login/interfaces/login.interfaces.ts';
import { EOtpPageType } from 'pages/auth/otp/enums/otp.enums.ts';
import { IOtpPageLocationState } from 'pages/auth/otp/interfaces/otp.iterfaces.ts';
import {
  SDescription,
  STitle,
  SOtpDescription,
  SResendCodeBtn,
  SResendCodeTile,
  SResendFormWrapper,
  SBottomContainer,
} from 'pages/auth/otp/otp.page.styles.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import ErrorTriangleIcon from 'assets/error-triangle.icon.svg?react';
import SuccessCircleIcon from 'assets/success-circle.icon.svg?react';

enum EFormFields {
  Otp = 'otp',
}

const OtpPage = (): ReactElement => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const location = useLocation() as IOtpPageLocationState;
  const [messageApi, statusMessageContextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { state } = location;
  const [countdownRestartFlag, setCountdownRestartFlag] = useState<number>(0);

  useEffect(() => {
    if (!(state?.email ?? state?.verificationFlowId ?? state?.type)) {
      navigate(-1);
    }
  }, [navigate, location, state?.email, state?.verificationFlowId, state?.type]);

  const onValuesChangeVerificationFlowHandler = (
    changedValues: {
      [EFormFields.Otp]: IInputMaskValue;
    }
  ): void => {
    const {
      otp: {
        unmaskedValue: otpValue,
      }
    } = changedValues;

    if (otpValue.length === 6) {
      form.submit();
    }
  };

  const onSubmitVerificationFlowHandler = async (
    values: {
      [EFormFields.Otp]: IInputMaskValue;
    }
  ): Promise<void> => {
    const {
      otp: {
        unmaskedValue: otpValue,
      }
    } = values;

    try {
      setSmartlookIdentity({
        userEmail: state?.email,
      });

      let returnTo: string | undefined = '';

      // eslint-disable-next-line no-void
      void messageApi.open({
        key: 'otp-submit',
        type: 'loading',
        content: t('otp.message.verificationInProgress.title'),
        duration: 0,
        style: {
          marginTop: 'calc(100vh - 60%)',
        },
      });

      if (state?.type === EOtpPageType.Login) {
        ({ return_to: returnTo } = await authGetLoginFlowService({ id: state.verificationFlowId ?? '' }));

        const requestLoginFlowData = {
          flow: state.verificationFlowId,
          updateLoginFlowBody: {
            code: otpValue,
            csrf_token: state.csrfToken,
            identifier: state.email,
            method: SessionAuthenticationMethodMethodEnum.Code
          },
        } as FrontendApiUpdateLoginFlowRequest;
        await authUpdateLoginFlowService(requestLoginFlowData);
      } else {
        ({ return_to: returnTo } = await authGetRegistrationFlowService({ id: state?.verificationFlowId ?? '' }));

        const requestRegistrationFlowData = {
          flow: state?.verificationFlowId,
          updateRegistrationFlowBody: {
            code: otpValue,
            csrf_token: state?.csrfToken,
            traits: {
              email: state?.email,
            },
            method: SessionAuthenticationMethodMethodEnum.Code
          },
        } as FrontendApiUpdateRegistrationFlowRequest;
        await authUpdateRegistrationFlowService(requestRegistrationFlowData);
      }

      await messageApi.open({
        key: 'otp-submit',
        type: 'success',
        content: t('otp.message.verificationCompleted.title'),
        icon: <SuccessCircleIcon />,
        duration: 0.5,
        style: {
          marginTop: 'calc(100vh - 60%)',
        },
      });

      if (returnTo) {
        window.location.replace(setHttps(returnTo));
      }
    } catch (e) {
      const error = e as ISubmitCatchError;
      const errorData = error.response.data;
      const errorMessageData = errorData?.ui?.messages?.[0];

      form.setFields([
        {
          name: EFormFields.Otp,
          errors: [''],
        },
      ]);

      if (
        errorMessageData?.id === 4000001
        && errorMessageData.text === 'The code was already used. Please request another code.'
      ) {
        await messageApi.open({
          key: 'otp-submit',
          content: t('otp.message.verificationError.codeAlreadyUsed.title'),
          icon: <span><ErrorTriangleIcon /></span>,
          duration: 2,
          style: {
            marginTop: 'calc(100vh - 60%)',
          },
        });
        setCountdownRestartFlag(value => value + 1);
      } else if (
        errorMessageData?.id === 4010008
        && errorMessageData.text === 'The login code is invalid or has already been used. Please try again.'
      ) {
        await messageApi.open({
          key: 'otp-submit',
          content: t('otp.message.verificationError.wrongCode.title'),
          icon: <span><ErrorTriangleIcon /></span>,
          duration: 2,
          style: {
            marginTop: 'calc(100vh - 60%)',
          },
        });
      } else {
        await messageApi.open({
          key: 'otp-submit',
          content: <Trans
            i18nKey="otp.message.verificationError.unexpectedError.title"
            components={{ prevScreen: <Link to={ROUTES_MAPPING.PUBLIC.LOGIN} /> }}
          />,
          icon: <span><ErrorTriangleIcon /></span>,
          duration: 0,
          style: {
            marginTop: 'calc(100vh - 60%)',
          },
        });
      }

      form.setFields([
        {
          name: EFormFields.Otp,
          value: '',
          errors: [],
        },
      ]);
    }
  };

  const resendVerificationCodeHandler = async (): Promise<void> => {
    try {
      // eslint-disable-next-line no-void
      void messageApi.open({
        key: 'otp-submit',
        type: 'success',
        content: t('otp.message.newCodeSent.title'),
        icon: <SuccessCircleIcon />,
        duration: 3,
        style: {
          marginTop: 'calc(100vh - 60%)',
        },
      });

      if (state?.type === EOtpPageType.Login) {
        const requestLoginFlowData = {
          flow: state.verificationFlowId,
          updateLoginFlowBody: {
            resend: 'code',
            csrf_token: state.csrfToken,
            identifier: state.email,
            method: SessionAuthenticationMethodMethodEnum.Code
          },
        } as FrontendApiUpdateLoginFlowRequest;
        await authUpdateLoginFlowService(requestLoginFlowData);
      } else {
        const requestRegistrationFlowData = {
          flow: state?.verificationFlowId,
          updateRegistrationFlowBody: {
            resend: 'code',
            csrf_token: state?.csrfToken,
            traits: {
              email: state?.email,
            },
            method: SessionAuthenticationMethodMethodEnum.Code
          },
        } as FrontendApiUpdateRegistrationFlowRequest;
        await authUpdateRegistrationFlowService(requestRegistrationFlowData);
      }

      setCountdownRestartFlag(value => value + 1);
    } catch (e) {
      const error = e as ISubmitCatchError;

      if (error.response.status !== 400) {
        captureError(error as IError);
      }

      setCountdownRestartFlag(value => value + 1);
    }
  };

  const rendererCountdown = (values: CountdownRenderProps): React.ReactNode => {
    const {
      completed,
      formatted: {
        seconds,
        minutes,
      },
    } = values;

    if (completed) {
      return <SResendCodeBtn
        id="verify-email-resend-code-lnk"
        onClick={resendVerificationCodeHandler}
      >
        {t('otp.ttl.resendCode.btn')}
      </SResendCodeBtn>;
    }

    return <SResendCodeTile>
      {t('otp.ttl.resendCode.title')} <span>{minutes}:{seconds}</span>
    </SResendCodeTile>;
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onValuesChange={onValuesChangeVerificationFlowHandler}
        onFinish={onSubmitVerificationFlowHandler}
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          marginTop: '16px',
        }}
      >
        <Rates />
        <Steps current={0} />
        <STitle>{t('otp.title')}</STitle>
        <SDescription className="p2">
          {t('otp.description')} <b>{location.state?.email ?? ''}</b>
        </SDescription>
        <SOtpDescription>
          {t('otp.description.2')}
        </SOtpDescription>
        <SResendFormWrapper>
          <FormItem
            name={EFormFields.Otp}
          >
            <InputMask
              id="verify-email-code"
              mask="000000"
              maskOptions={{
                placeholderChar: '*',
                lazy: false,
              }}
              inputMode="numeric"
            />
          </FormItem>
          <Countdown
            key={countdownRestartFlag}
            renderer={rendererCountdown}
            date={Date.now() + (1000 * 60 * 2) - 1000}
          />
        </SResendFormWrapper>
        <SBottomContainer>
          <PoweredBy />
        </SBottomContainer>
      </Form>
      {statusMessageContextHolder}
    </>
  );
};

export default OtpPage;
