export const amountDigitSeparator = (amount: string | number): string => {
  const separateNumber = amount.toString().split('.') as [string, string | undefined];
  const [integerPart, decimalPart] = separateNumber;
  const formattedInteger = new Intl.NumberFormat('en-US', {
    useGrouping: true,
    minimumFractionDigits: 0
  }).format(Number(integerPart)).replace(/,/g, ' ');

  return decimalPart !== undefined
    ? `${formattedInteger}.${decimalPart}`
    : formattedInteger;
};
