import styled from 'styled-components';

export const SContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  display: flex;
  flex: 1;
  margin-bottom: 0;
  margin-top: 24px;
`;
