import { Form } from 'antd';
import styled from 'styled-components';

export const SFormItem = styled(Form.Item)`
  padding: 0 0 16px;
  margin: 0;
  
  .ant-form-item-label {
    padding: 0 16px 4px !important;
    
    & > label {
      font-family: "Plus Jakarta Sans", sans-serif;
      color: ${props => props.theme.themeV3.colors.grey700};
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      
      &.ant-form-item-required {
        &:before {
          display: none !important;
        }

        &:after {
          font-family: "Plus Jakarta Sans", sans-serif !important;
          color: ${props => props.theme.themeV3.colors.grey700} !important;
          font-size: 14px !important;
          font-weight: 500 !important;
          line-height: 22px !important;
          margin-inline-end: 0 !important;
          content: "*" !important;
          visibility: visible !important;
        }
      }
    }
  }
  
  .ant-form-item-extra {
    margin-top: 4px;
    padding: 0 16px;
    color: ${props => props.theme.themeV3.colors.grey400};
    font-variant-numeric: lining-nums tabular-nums;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }

  .ant-form-item-explain-error {
    display: flex;
    justify-content: flex-end;
    padding: 0 16px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: ${props => props.theme.themeV3.colors.red300};
  }

  &.ant-form-item-has-error {
    .ant-input-affix-wrapper {
      background: ${props => props.theme.themeV3.colors.red100} !important;
      border: 1px solid ${props => props.theme.themeV3.colors.red300} !important;
      margin-bottom: 4px;

      input::placeholder {
        color: ${props => props.theme.themeV3.colors.red200} !important;
      }
    }
    
    .spn-phone-number-input {
      background: ${props => props.theme.themeV3.colors.red100} !important;
      border-bottom: 1px solid ${props => props.theme.themeV3.colors.red300} !important;
      border-left: 1px solid ${props => props.theme.themeV3.colors.red300} !important;
      border-right: 1px solid ${props => props.theme.themeV3.colors.red300} !important;
      
      .ant-input-wrapper,
      .ant-input-affix-wrapper {
        input {
          background: transparent !important;
          border: 0 !important;
          margin-bottom: 0;
        }
      }
    }
  }
`;
