import { LoginFlow, RegistrationFlow, UiNode } from '@ory/kratos-client';
import { LoaderFunctionArgs } from 'react-router-dom';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { authCreateRegistrationFlowService, authGetLoginFlowService } from 'domains/auth/services/auth.service.ts';
import { TUiNodeAttributes } from 'domains/auth/types/auth.types.ts';
import { ILoginPageLoaderData } from 'pages/auth/login/interfaces/login.interfaces.ts';

export const loginPageLoader = async ({ request }: LoaderFunctionArgs): Promise<ILoginPageLoaderData> => {
  try {
    const returnToQueryParamValue = new URL(request.url).searchParams.get(EUrlQueryParams.ReturnTo) ?? '';
    const flowQueryParamValue = new URL(request.url).searchParams.get(EUrlQueryParams.Flow) ?? '';

    let registrationFlowData = {};
    let loginFlowData = {};

    if (flowQueryParamValue) {
      loginFlowData = await authGetLoginFlowService({ id: flowQueryParamValue });
    } else {
      registrationFlowData = await authCreateRegistrationFlowService({
        ...(returnToQueryParamValue ? { afterVerificationReturnTo: returnToQueryParamValue } : null),
        ...(returnToQueryParamValue ? { returnTo: returnToQueryParamValue } : null),
      });
    }

    const flowData = (
      Object.keys(loginFlowData).length ? loginFlowData : registrationFlowData
    ) as RegistrationFlow | LoginFlow;

    const initialValues = flowData.ui.nodes.reduce(
      (acc: Record<string, string | number>, node: UiNode) => {
        const attributes = node.attributes as TUiNodeAttributes;

        return {
          ...acc,
          [attributes.name]: attributes.value as string,
        };
      },
      {},
    );

    return {
      flowData,
      initialValues,
    };
  } catch (error: unknown) {
    return {
      flowData: {},
      initialValues: {},
    };
  }
};
