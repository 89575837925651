import Smartlook from 'smartlook-client';
import {
  ENV_SMARTLOOK_TOKEN,
  IS_LOCAL,
  IS_STAGE,
} from 'common/constants/env.constants.ts';
import { isWebview } from 'common/utils/web-view.utils.ts';
import { ITransactionDataRes } from 'domains/transaction/interfaces/transaction.interfaces.ts';

export const initSmartlook = (): void => {
  Smartlook.init(ENV_SMARTLOOK_TOKEN, {
    region: 'eu',
    advancedNetwork: {
      allowedUrls: [
        /https:\/\/[a-zA-Z0-9-.]*armenotech\.dev\/.*/,
        /https:\/\/[a-zA-Z0-9-.]*armenotech\.net\/.*/,
        /https:\/\/[a-zA-Z0-9-.]*aps\.money\/.*/,
        /https:\/\/[a-zA-Z0-9-.]*proc-gw\.com\/.*/,
      ],
      websockets: true,
    },
  });
  Smartlook.record({
    emails: true,
    forms: true,
    numbers: true,
    ips: true,
  });
};

interface ISmartlookIdentityData {
  interactiveID?: string;
  userIdentityId?: string;
  transactionData?: ITransactionDataRes | undefined;
  userEmail?: string;
  kyc_status?: string;
  kyc_level?: string;
  user_city?: string;
  user_country?: string;
  wallet_name?: string;
  isAnonymous?: boolean;
}

export const SMARTLOOK_ANONYMOUS_SESSION_ID = 'FE_ANONYMOUS';

export const setSmartlookIdentity = (data: ISmartlookIdentityData): void => {
  const {
    interactiveID = '',
    userIdentityId = '',
    userEmail = '',
  } = data;

  const sessionId = interactiveID || userIdentityId || userEmail;

  const reqData = {
    isWebview: isWebview(),
    ...(data.interactiveID ? { interactive_id: data.interactiveID } : {}),
    ...(data.userIdentityId ? { customer_id: data.userIdentityId } : {}),
    ...(data.userEmail ? { email: data.userEmail } : {}),
    ...(data.transactionData?.kind ? { direction: data.transactionData.kind } : {}),
    ...(data.transactionData?.blockchain ? { network: data.transactionData.blockchain } : {}),
    ...(data.transactionData?.amount ? { pay_amount: data.transactionData.amount } : {}),
    ...(data.transactionData?.asset_code ? { pay_currency: data.transactionData.asset_code } : {}),
    ...(data.kyc_status ? { kyc_status: data.kyc_status } : {}),
    ...(data.kyc_level ? { kyc_level: data.kyc_level } : {}),
    ...(data.user_city ? { user_city: data.user_city } : {}),
    ...(data.user_country ? { user_country: data.user_country } : {}),
    ...(data.wallet_name ? { wallet_name: data.wallet_name } : {}),
  };

  if (IS_LOCAL || IS_STAGE) {
    console.warn('Smartlook session ID:', `'${sessionId}'`);
    console.warn('Add to Smartlook data:');
    console.table(reqData);
  }

  if (sessionId) {
    Smartlook.identify(sessionId, reqData);
  } else if (IS_LOCAL || IS_STAGE) {
    console.warn('Smartlook: empty session ID');
  }
};

export const finishSmartlookSession = (): void => {
  Smartlook.disable();
};
