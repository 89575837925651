import { ReactElement, useEffect, useCallback, useState } from 'react';
import { message } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import Button from 'common/components/button/button.component.tsx';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { ETransactionKind } from 'domains/transaction/enums/transaction.enums.ts';
import { IFinalStepPageLoaderData } from 'pages/final-step/interfaces/final-step.interfaces.ts';
import SuccessCircleBigIcon from 'assets/success-circle-big.icon.svg?react';
import {
  SContent,
  SWarningIcon,
  STitle,
  SDescription,
  SButtonWrapper,
} from './final-step.page.styles.ts';

const FinalStepPage = (): ReactElement => {
  const { t } = useTranslation();
  const { transactionData } = useLoaderData() as IFinalStepPageLoaderData;
  const { kind, postMessage: postMessageFromTransactionData } = transactionData;
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const redirectUrlQueryParamValue = searchParams.get(EUrlQueryParams.RedirectUrl);
  const postMessageQueryParamValue = searchParams.get(EUrlQueryParams.PostMessage) ?? undefined;
  const postMessage = postMessageFromTransactionData ?? postMessageQueryParamValue;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (redirectUrlQueryParamValue) {
        const isInIframe = window.self !== window.top;

        if (isInIframe) {
          window.parent.location.href = redirectUrlQueryParamValue;
        } else {
          window.location.href = redirectUrlQueryParamValue;
        }
      }
    }, 3000);

    return () => { clearTimeout(timer); };
  }, [redirectUrlQueryParamValue]);

  useEffect(() => () => {
    message.destroy();
  }, []);

  const content = {
    [ETransactionKind.Deposit]: {
      noAutomaticRedirect: {
        title: t('finalStep.closeScreen'),
        description: t('finalStep.returnToWallet'),
        icon: <SuccessCircleBigIcon />,
        buttonLabel: '',
      },
      withAutomaticRedirect: {
        title: t('finalStep.redirecting'),
        description: t('finalStep.redirectingDescription'),
        icon: <SWarningIcon />,
        buttonLabel: t('finalStep.btn.continue'),
      },
    },
    [ETransactionKind.Withdrawal]: {
      noAutomaticRedirect: {
        title: t('finalStep.confirmWithdrawal'),
        description: t('finalStep.withdrawalDescription'),
        icon: <SuccessCircleBigIcon />,
        buttonLabel: '',
      },
      withAutomaticRedirect: {
        title: t('finalStep.confirmWithdrawal'),
        description: t('finalStep.redirectWithdrawalDescription'),
        icon: <SWarningIcon />,
        buttonLabel: t('finalStep.btn.continue'),
      },
    },
  };

  const sendPostMessage = useCallback((): void => {
    const message = {
      transaction: transactionData,
    };

    const isInIframe = window.self !== window.top;

    if (isInIframe) {
      window.parent.postMessage(message, '*');
    } else {
      window.postMessage(message, '*');
    }
  }, [transactionData]);

  useEffect(() => {
    sendPostMessage();
  }, [sendPostMessage]);

  const handleContinueClick = (): void => {
    sendPostMessage();
    setIsButtonVisible(false);
    // eslint-disable-next-line no-void
    void message.open({
      content: t('finalStep.popup.message'),
      duration: 0,
      style: {
        marginTop: 'calc(100vh - 210px)',
      },
    });

    if (redirectUrlQueryParamValue) {
      window.location.href = redirectUrlQueryParamValue;
    }
  };

  const messageType = (postMessage === 'true' || postMessage === undefined)
    ? 'withAutomaticRedirect'
    : 'noAutomaticRedirect';
  const { title, description, icon, buttonLabel } = content[kind][messageType];

  return (
    <>
      <SContent>
        {icon}
        <STitle>{title}</STitle>
        <SDescription className="p2">{description}</SDescription>
      </SContent>
      <SButtonWrapper>
        {isButtonVisible && messageType === 'withAutomaticRedirect' && (
          <Button onClick={handleContinueClick} block>
            <Trans i18nKey={buttonLabel} />
          </Button>
        )}
      </SButtonWrapper>
    </>
  );
};

export default FinalStepPage;
